.sectionTitleH3 {
    font-size: 3.6rem;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 1.5rem;
    font-family: 'Roboto' !important;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    padding: 0 5rem;
}
