//Hot product title
.home__hot__products {
    padding: 5rem 0 6rem 0;
}

// Hot product items
.hot__product__content {
    display: flex;
    flex-direction: column;
}

.list__products {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
}

.product__card {
    width: calc(25% - 3rem);
    margin: 0 1.5rem;
    margin-bottom: 3.2rem;
    box-shadow: 0px 2px 1.5px 0px #ccc;
    border-top: 1px solid #f1f1f1;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover &--content {
        top: 0;
    }

    &--tag {
        display: flex;
        width: 100%;
        position: absolute;
        padding: 1.6rem;
        top: 0;
        justify-content: space-between;
        z-index: 1;
        align-items: center;

        .new__tag {
            font-size: 1.3rem;
            font-weight: 800;
            background: #d3b673;
            transform: rotate(-15deg);
            border-radius: 50%;
            color: white;
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .discount__tag {
            font-size: 1.3rem;
            font-weight: 800;
            background: #282828;
            border-radius: 50%;
            color: #d3b673;
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &--img--warp {
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &--content {
        background: #f5f5f5;
        transition: all 0.2s;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: 42px;

        .product__title {
            width: 100%;
            font-size: 1.6rem;
            font-weight: 500;
            text-align: center;
            margin-top: 1.8rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 8px;
        }

        .product__price {
            display: flex;
            align-items: center;
            justify-content: center;

            &--origin {
                font-size: 1.4rem;
                font-weight: 600;
                color: #8a733f;
                text-align: center;
                margin-top: 24px;
            }

            &--sale {
                font-size: 1.4rem;
                font-weight: 600;
                color: #8a733f;
                text-align: center;
                margin-top: 2.4rem;
                margin-left: 8px;
                color: #bebebe !important;
                transform: scale(0.9, 0.9);
                text-decoration: line-through;
            }
        }

        .btn__order__product {
            padding: 2px 16px;
            margin-top: 16px;
            margin-bottom: 12px;
        }
    }
}

.btn__view__all {
    margin-top: 1.6rem;
    align-self: center;
}
