.responsive {
    display: none;
}

@media (max-width: 1100px) {
    html {
        overflow: hidden;
    }

    .responsive {
        position: relative;
        padding: 150px 60px;
        color: white;
        font-weight: 600;
        background-image: linear-gradient(90deg, #5ebbff, #a174ff);
        font-size: 30px;
        display: block;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        z-index: 9999;
    }
}
