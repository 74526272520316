.left {
    display: flex;
    align-items: center;
}

.logo__img {
    width: 20rem;
}

.menu {
    &__primary {
        margin-left: 3rem;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        position: relative;
        font-size: 1.4rem;
        margin-right: 2.5rem;
        padding: 0.8rem 0;
        font-weight: 700;
        text-transform: uppercase;

        // Hover change submenu display
        &:hover {
            .sub__menu {
                display: block;
            }

            & > a {
                padding-bottom: 0.1rem;
                border-bottom: 0.2rem solid white;
            }
        }

        &--current {
            padding-bottom: 0.1rem;
            border-bottom: 0.2rem solid white;
        }

        a {
            display: flex;
            align-items: center;
            color: white;
        }
    }

    &__icon {
        font-size: 1.5rem;
        font-weight: 700;
    }
}

.sub__menu {
    display: none;
    position: absolute;
    width: 220px;
    background: #292929;
    top: 100%;
    z-index: 2;
    padding: 0.8rem 0;

    &--item {
        padding: 0.8rem 1.5rem;
        margin: 0;
        font-weight: 700;
        font-size: 1.4rem;
        text-transform: uppercase;

        &:hover a {
            color: #d3b673;
        }
    }

    &--item:not(:last-child) {
        border-bottom: 1px solid #494949;
    }
}
