.section__content {
    display: flex;
    justify-content: center;
}

// CONTENT LEFT
.section__content--left {
    width: 50%;
    margin-right: 8px;
    display: flex;
    flex-wrap: wrap;
}

.card__news {
    width: calc(50% - 8px);
    background: #f5f5f5;
    margin-bottom: 30px !important;
    cursor: pointer;
    overflow: hidden;
    transition: all 0.2s;
    text-decoration: none;
    color: black;

    &:hover,
    &:active {
        box-shadow: 3.5px 6px 18px 0 rgb(0 0 0 / 10%);
    }

    &:hover .card__img,
    &:active .card__img {
        transform: scale(1.1);
    }
}

// First card will span 100% width
.card__news:first-child {
    width: 100%;
    margin: 0;
}

.card__news:nth-child(2n) {
    margin-right: 8px;
}

.wrap__card__img {
    overflow: hidden;
}

.card__img {
    width: 100%;
    transition: all 0.2s;
}

.card__info {
    padding: 1.5rem 1.5rem;

    &--title {
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-bottom: 5px;
        color: #282828;
        font-weight: 600;
    }

    &--description {
        font-size: 1.3rem;
        color: #5c5c5c;
        margin-top: 3.2rem;
        margin-bottom: 1.6rem;
    }
}

// CONTENT RIGHT
.section__content--right {
    width: 50%;
    margin-left: 8px;
}

.wrap__video__img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        width: 100%;
        transition: all 0.2s;
    }

    &:hover img {
        transform: scale(1.1);
    }
}

.home__btn__play {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    font-size: 5rem;
    color: white;

    &:hover {
        color: #d3b673;
    }
}

.video {
    margin-bottom: 30px;
}

.video1 {
    position: relative;
}

.video2 {
    display: flex;
    align-items: center;
}

.video2 > .wrap__video__img {
    min-width: 160px;
    max-width: 160px;
}

.video1 > .video__title {
    position: absolute;
    font-family: 'Roboto', sans-serif;
    bottom: 4px;
    color: white;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    padding: 0 8px;
    cursor: pointer;
}

.video2 > .video__title {
    color: #282828;
    font-size: 1.6rem;
    font-weight: 500;
    font-family: 'Roboto';
    word-break: keep-all;
    padding-left: 1.6rem;
    cursor: pointer;
}
