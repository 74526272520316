.popupContainer {
    position: relative;
}

.videoPopup {
    width: 650px;
    height: 400px;
    top: calc(50% - 200px);
    left: calc(50% - 325px);
    z-index: 5;
    background: transparent;
    position: fixed;
    display: flex;
    justify-content: center;
    border: none;
}

.videoCloseIcon {
    font-size: 1000px;
    top: 0;
    color: red;
}
