// Product infomation

.popup__add__product {
    width: 65rem;
    height: 50rem;
    top: calc(50% - 25rem);
    left: calc(50% - 32.5rem);
    background: #fbfbfb;
    padding-top: 10px;
}

.popup__content {
    width: 100%;
    overflow: hidden;
}

.popup__product {
    border-radius: 6px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.product__infomation {
    display: flex;
    padding: 0 20px;
    padding-bottom: 12px;

    &--right {
        margin-left: 16px;
    }

    &--img {
        width: 18rem;
        height: 18rem;
        border-radius: 6px;
        border: 1px solid #f4f4f4;
    }

    &--name {
        font-size: 1.8rem;
        margin-right: 16px;
        font-weight: 600;
    }

    &--price {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .product__price--sale {
            font-size: 1.3rem;
            padding-right: 10px;
            color: #8a733f;
            font-weight: 600;
        }

        .product__price--origin {
            font-size: 1.4rem;
            text-decoration: line-through;
        }
    }

    &--info {
        font-size: 1.4rem;
        padding: 12px 0;
        color: rgba(0, 0, 0, 0.87);
        font-style: italic;
    }
}

.wrap__quantity {
    display: flex;
    align-items: center;
}

.change__amount__quantity {
    display: flex;
    align-items: center;
}

.btn__price__product {
    font-size: 1.3rem;
    background: #d8b979;
    color: #ffffff;
    padding: 0 20px;
    height: 3.2rem;
    line-height: 3.2rem;
    border-radius: 30px;
    border: none;
    box-shadow: unset;
    margin-left: 30px;
    cursor: pointer;
}

// Product customize

.product__customize {
    overflow-y: scroll;
    padding: 20px;
    flex-grow: 1;
    height: 50%;
}

.custom__scrollbar::-webkit-scrollbar {
    width: 4px;
}

.custom__scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom__scrollbar::-webkit-scrollbar-thumb {
    background: #888;
}

.customize {
    margin-bottom: 8px;

    &__title {
        padding: 0 20px;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 1.6rem;
    }

    &__content {
        display: flex;
        padding: 0 20px;
        background: #ffffff;
        align-items: center;
        flex-wrap: wrap;
        box-shadow: 0 0 12px 0 rgb(0 0 0 / 5%);
        border-radius: 4px;
    }
}

.container__radio {
    width: 50%;
    display: block;
    position: relative;
    padding-left: 24px;
    padding-block: 12px;
    cursor: pointer;
    font-size: 1.4rem;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */

    > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
}

.container__checkbox {
    display: block;
    position: relative;
    padding-left: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 0px;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */

    > input {
        position: absolute;
        opacity: 1;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

.topping__wrap {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-block: 8px;
}

.topping__price {
    font-size: 1.4rem;
}

// Logic custom input radio

.checkmark__radio {
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: 50%;
}

input[type='radio']:hover ~ .checkmark__radio {
    background: #ccc;
}

input[type='radio']:checked ~ .checkmark__radio {
    background-color: var(--primary-color);
}

// Logic custom input checkbox

.checkmark__checkbox {
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #eee;
}

input[type='checkbox']:hover ~ .checkmark__checkbox {
    background: #ccc;
}

input[type='checkbox']:checked ~ .checkmark__checkbox {
    background-color: var(--primary-color);
}
