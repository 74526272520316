/*-----------------------------------*\
 * # RESET CSS
\*-----------------------------------*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 10px;
    font-family: 'Lato', sans-serif;
    font-family: 'Mulish', sans-serif;
    font-family: 'Poppins', sans-serif;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
}

html::-webkit-scrollbar {
    display: none;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

img {
    vertical-align: middle;
    border-style: none;
}

:root {
    --primary-color: #d8b979;
}

/*-----------------------------------*\
 * # RE-USE
\*-----------------------------------*/

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container {
    padding: 0 !important;
}

.marginTop-medium {
    margin-top: 3.2rem;
}

.overflow-hidden {
    overflow: hidden;
}

/* CSS for pages */
.page__title {
    font-family: 'Roboto', sans-serif !important;
    font-size: 3.6rem !important;
    font-weight: 600;
    margin: 3.2rem 0 1.6rem 0;
}

.page__top__banner {
    height: 300px;
    text-transform: uppercase;
    font-size: 36px;
    font-weight: 600;
    color: #d3b673;
    letter-spacing: 1.8px;
    font-family: 'Roboto';
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(./assets/banner/breadcrumb_bg5.png) no-repeat center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 30px;
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
}

.page__text {
    font-family: 'Mulish';
    font-size: 1.5rem;
    line-height: 1.4;
    color: #333;
    margin-bottom: 1.5rem;
}

.page__wrap__img {
    text-align: center;
    margin: 5rem;
}

.page__img {
    width: 80%;
    margin-bottom: 5rem;
}

/* Re-use popup */

.popup {
    z-index: 5;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 6%);
    border-radius: 3px;
    background: #ffffff;
    position: fixed;
    padding: 40px 0px 10px 0;
    display: flex;
    justify-content: center;
}

/* Re-use close button */

.close__btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    z-index: 6;
    transition: all 0.2s;

    &:hover {
        transform: scale(1.2);
    }
}

.close__btn--icon {
    font-size: 3rem;
}
