.home__franchise {
    display: flex;
    border: solid 6px #d3b673;
    margin-top: 2rem;
    margin-bottom: 6rem;
}

.home__franchise--left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10%;

    .franchise__description {
        text-align: center;
        font-family: 'Roboto';
        color: #504d4d;
        font-weight: 300;
        font-size: 1.6rem;
        margin: 1.6rem 0;
    }
}

.home__franchise--right {
    width: 50%;
    display: flex;
    justify-content: center;
}

.img__banner__franchise {
    width: 70%;
}
