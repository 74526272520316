footer {
    background: url(../../assets//background/ft_bg_img.png) no-repeat center;
    background-size: cover;
    position: relative;
}

.footer__wrap {
    z-index: 1;
    position: relative;
    padding-top: 55px !important;
    color: white;
    font-size: 1.3rem;
}

.footer__main {
    display: flex;
}

.footer__1 {
    padding-left: 30px;
    width: 40%;
}

.footer__2 {
    padding-left: 30px;
    width: 18%;
}

.footer__logo {
    width: 24%;
    height: max-content;
}

.footer__title {
    font-family: 'Roboto', sans-serif;
    color: #d3b673;
    margin-bottom: 30px;
    font-style: normal !important;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
}

.footer__icon {
    color: #d3b673;
    font-size: 1.8rem;
    margin-right: 1rem;

    &:hover {
        color: blanchedalmond;
    }
}

.footer__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.4rem;
}

.menu__footer--item {
    margin-bottom: 1.4rem;

    a {
        color: white;
        font-size: 1.3rem;
        margin-bottom: 1.4rem;
    }

    &:hover a {
        color: #d3b673;
    }
}

.footer__info.social {
    margin-bottom: 2rem;
}

.footer__info.download__app {
    display: flex;
    width: 65%;
    flex-wrap: wrap;
    justify-content: space-between;

    a {
        width: 48%;
        margin-bottom: 8px;

        img {
            width: 100%;
        }
    }
}

.footer__bottom {
    border-top: 1px solid white;
    display: flex;
    justify-content: space-between;
}

.footer__left {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    margin: 20px 4px;
}

.footer__right {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}

.footer__cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    background: rgba(51, 51, 51, 0.86);
}
