// ORDER HEADER

.order__header {
    display: flex;
    justify-content: space-between;
    box-shadow: 0 2px 10px 0 rgb(0 0 0 / 6%);
    height: 5rem;
    align-items: center;
    padding: 0 16px;
    background: #ffffff;
    position: relative;
}

.order__logo {
    width: 15rem;
    height: 4rem;
}

.order__search__input {
    width: 50rem;
    border-radius: 30px;
    border: none;
    outline: 2px solid #ccc;
    background: #fbfbfb;
    height: 3.2rem;
    font-size: 14px;
    padding: 0 20px 0 30px;
    background-image: url(../assets/icon/icon-search.svg);
    background-repeat: no-repeat;
    background-position: 8px 10px;
    background-size: 14px 14px;

    &:focus-visible {
        outline: 2px solid #bfb2b2;
    }
}

.login__btn {
    padding: 6px 15px;
    background: var(--primary-color);
    color: #ffffff;
    text-decoration: none;
    font-size: 1.4rem;
    line-height: normal;
    border-radius: 25px;
    white-space: nowrap;

    &:hover {
        opacity: 0.8;
    }
}

// PAGE ORDER

.page__order {
    background: #fbfbfb !important;
    min-height: 100vh;
}

.order__content {
    display: flex;
    padding-top: 8px;
    max-width: 120rem;
    margin: 0 auto;
}

.order__sidebar {
    width: 30rem;
    max-width: 25%;
    margin: 0 16px;
    color: #282828;
    background: #ffffff;
    height: fit-content;
    box-shadow: 0 2px 7px 0 rgb(0 0 0 / 5%);
    border-radius: 4px;
    padding-bottom: 12px;
}

// RE-USE STYLE FOR THIS ORDER PAGE

.btn__increase {
    cursor: pointer;
    border-radius: 50%;
    background: #799dd9;
    color: #ffffff;
    height: 2rem;
    width: 2rem;
    text-align: center;
    line-height: 2rem;
    font-size: 26px;
    font-weight: 600;
    position: absolute;
    right: 15px;
    bottom: 15px;
}

#scrollToTop {
    position: fixed;
    bottom: 5rem;
    right: 3rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    text-align: center;
    line-height: 6rem;
    font-size: 2.5rem;
    color: white;
    background-color: #799dd9;
    transform: translateY(1);
    cursor: pointer;

    &:hover {
        transform: translateY(-1.5px);
        opacity: 0.8;
    }
}
