.search {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d3b673;
    color: white;
    font-weight: 900;
    font-size: 2rem;
}
